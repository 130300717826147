import React, { useState } from 'react';
import '../CSS/NavBarComponent.css';
import logo from '../Images/logo-2.png';
import { NavLink, Link } from 'react-router-dom';

const NavBarCompnent = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const closeNav = () => {
        setIsNavOpen(false);
    };

    const navLinkStyle = ({ isActive }) => {
        return {
            color: isActive ? '#ff0101' : 'rgb(0 0 111)',
        };
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to="/">
                        <a className="navbar-brand" href="logo">
                            <img src={logo} alt="logo" />
                        </a>
                    </Link>
                    <button
                        className={`navbar-toggler ${isNavOpen ? 'collapsed' : ''}`}
                        type="button"
                        onClick={toggleNav}
                        aria-expanded={isNavOpen ? 'true' : 'false'}
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink className="nav-link" style={navLinkStyle} onClick={closeNav} to="/">
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" style={navLinkStyle} onClick={closeNav} to="/nepal-tour-package">
                                    Tours
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    style={navLinkStyle}
                                    onClick={closeNav}
                                    to="/nepal-tour-package-for-couple"
                                >
                                    Adventures
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" style={navLinkStyle} onClick={closeNav} to="/best-nepal-tour-packages">
                                    Hotels
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    style={navLinkStyle}
                                    onClick={closeNav}
                                    to="/nepal-tour-package-for-family"
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" style={navLinkStyle} onClick={closeNav} to="/nepal-travel-packages">
                                    Contact Us
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavBarCompnent;