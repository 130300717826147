import React,{useLayoutEffect} from 'react'
import "../CSS/ToursInfo.css";
import { MetaTags } from 'react-meta-tags';

////////////// Form ///////////////////////
import ItineraryForm from './ItineraryForm';


/////////////// Acordion ////////////////////
import images01 from "../Images/K-I-F-image.jpg";
import images02 from "../Images/F-I-S-image.jpg";
import FooterComponent from './FooterComponent';


const KathmanduItinerary = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
      <MetaTags>
        <title>Nepal Tours And Travels | Vision Holidays</title>
        <meta title="Nepal Tours And Travels | Vision Holidays" />
        <meta
          name="description"
          content="Experience the extraordinary charm of Nepal through Vision Holidays exceptional Nepal Tours And Travels."
        />
        <meta
          name="keywords"
          content="nepal tour package, nepal tour package for couple, best nepal tour packages, nepal tour package for family, nepal travel packages, nepal tours and travels"
        />
        <link rel="canonical" href="https://www.nepaltour.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Nepal Tours And Travels | Vision Holidays" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.nepaltour.in/" />
        <meta property="og:description" content="Experience the extraordinary charm of Nepal through Vision Holidays exceptional Nepal Tours And Travels." />
      </MetaTags>
            {/* ////////////////////////////// section 1 //////////////////////////////// */}

            <section className='ToursInfo-Section'>
                <div className='ToursInfo-banner-image'>
                </div>
            </section>

            {/* //////////////////////////////// section 2 //////////////////////////// */}

            <section className='ToursInfo-Section2'>
                <div className='container'>
                    <div className='ToursInfo-TourName'>
                        <h1>Kathmandu </h1>
                        <h4>04 Nights / 05 Days</h4>
                    </div>
                </div>
            </section>

            {/* //////////////////////////////// section 3 //////////////////////////// */}

            <section className='ToursInfo-Section2'>
                <div className='container'>
                    <div className='ToursInfo-Section3-Itinerary'>
                        <div className='row'>

                            <div className='col-md-12 col-sm-12'>
                                <div className='Itinerary-div'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Day 01 – Kathmandu Arrival
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Meet at Kathmandu Airport and Transfer to Hotel. Check in at hotel. Over Night Stay in Kathmandu</p>
                                                    <p>(Optional – Nepali Cultural Show with Dinner)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Day 02 – Kathmandu Sightseeing
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast At 10 Visit - Pashupatinath / Boudhanath / Swyambhunath / Kathmandu Durbar.Over Night Stay in Kathmandu</p>
                                                    <p>( Optional – Mt Everest Flight – 6:30 AM )</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Day 03 – Kathmandu Sightseeing
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast at 10 AM Visit - Bhaleshwar Mahadev Temple @ Chandragiri Hills. By Cable Car.Overnight Stay in Kathmandu</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Day 04 – Kathmandu Sightseeing
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour " data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast at 10 AM Visit - Buddhaneelkanth (Sleeping Vishnu) / Patan Durbar Square.
                                                        Over Night Stay In Kathmandu</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFive">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Day 05 – Kathmandu Airport Drop
                                                </button>
                                            </h2>
                                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast Transfer To Kathmandu Airport</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {   /* ////////////////////////////////////////////////////// section 4 ///////////////////////////////////////////////////////// */}

            <section className='ToursInfo-Section4'>
                <div className='container'>
                    <div className='Offer'>
                        <div className='startAt'>
                            <div className='Start'>
                                <p>Package Start  @</p>
                                <h5>Rs 11,000/- + 5 % GST</h5>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='ToursInfo-Section4-Data'>
                                <div className='Cost-Includes'>
                                    <h4>Includes :</h4>
                                    <hr></hr>
                                    <ul className='list_ok'>
                                        <li>04 Nights / 05 Days Stay in Kathmandu with Breakfast</li>
                                        <li>Two International Airport Transfer in Kathmandu by Pvt Air Condition Vehicle</li>
                                        <li>Kathmandu Sightseeing as Per Itinerary by Pvt Air Condition Vehicle</li>
                                        <li>All Applicable Hotel Tax 24.3% Included</li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Kathmandu Sightseeing :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Pasupatinath Temple / Bouddhanath Stupa / Swambhuntah Stupa / Kathmandu Durbar
                                        </li>
                                        <li>
                                            Bhaleshwar Mahadev Temple @ Chandragiri Hills.
                                        </li>
                                        <li>
                                            Buddhaneelkanth ( Sleeping Vishnu ) / Patan Durbar Square
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Exclude –</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Entrance Fees During Sightseeing / Cable Car Ticket / Tour Guide / Air Ticket
                                        </li>
                                    </ul>
                                </div>

                                <div className='Note'>
                                    <h4>Note :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Booking is subject to availability and confirmation at the Time of Booking .
                                        </li>
                                        <li>
                                            Prices/Taxes are subject to changes and availability.
                                        </li>
                                        <li>
                                            Confirmed bookings will attract a cancellation fee
                                        </li>
                                    </ul>
                                </div>

                                <div className='Important'>
                                    <h4>Important :-</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            All Passenger Must Carry Valid Indian Passport OR Election Card
                                        </li>
                                        <li>
                                            Vehicle Not At Disposal. All Transfer &amp; Sightseeing On Point To Point Basis
                                        </li>
                                        <li>
                                            Rates Valid For Indian Passport Holder Only.
                                        </li>
                                        <li>
                                            Any Additional Expenses Incurred Due to Flight Cancel/Delay/Natural Disaster/Political Action
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>

                            <ItineraryForm />
                            <div className='formcont'>

                                <div className='Side-Images'>
                                    <div className='Side-Images01'>
                                        <img src={images01} alt="" />
                                    </div>
                                    <div className='Side-Images02'>
                                        <img src={images02} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent />


        </>
    )
}

export default KathmanduItinerary;