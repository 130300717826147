import React,{useLayoutEffect} from 'react'
import "../CSS/ToursInfo.css"

////////////// Swiper ///////////////////////

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
// import required modules

/////////////// Acordion ////////////////////
import images01 from "../Images/KPC-I-F-Image.jpg";
import images02 from "../Images/KPC-I-F-Image02.jpg";
import FooterComponent from './FooterComponent';

import { MetaTags } from 'react-meta-tags';
////////////// Form ///////////////////////
import ItineraryForm from './ItineraryForm';

const KPCItinerary = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
      <MetaTags>
        <title>Cheap Nepal Tour Packages | Vision Holidays</title>
        <meta title="Cheap Nepal Tour Packages | Vision Holidays" />
        <meta
          name="description"
          content="Uncover the hidden gems of Nepal with our Cheap Nepal Tour packages. From majestic mountains to ancient temples, embark on a journey of a lifetime"
        />
        <meta
          name="keywords"
          content="nepal tour package, nepal tour package for couple, best nepal tour packages, nepal tour package for family, nepal travel packages, nepal tours and travels"
        />
        <link rel="canonical" href="https://www.nepaltour.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Cheap Nepal Tour Packages | Vision Holidays" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.nepaltour.in/" />
        <meta property="og:description" content="Uncover the hidden gems of Nepal with our Cheap Nepal Tour packages. From majestic mountains to ancient temples, embark on a journey of a lifetime" />
      </MetaTags>
            {/* ////////////////////////////// section 1 //////////////////////////////// */}

            <section className='ToursInfo-Section'>
                <div className='ToursInfo-banner-image'>
                </div>
            </section>

            {/* //////////////////////////////// section 2 //////////////////////////// */}

            <section className='ToursInfo-Section2'>
                <div className='container'>
                    <div className='ToursInfo-TourName'>
                        <h1>Kathmandu + Pokhara + Chitwan</h1>
                        <h4>7 Nights / 8 Days</h4>
                    </div>
                </div>
            </section>

            {/* //////////////////////////////// section 3 //////////////////////////// */}

            <section className='ToursInfo-Section2'>
                <div className='container'>
                    <div className='ToursInfo-Section3-Itinerary'>
                        <div className='row'>

                            <div className='col-md-12 col-sm-12'>
                                <div className='Itinerary-div'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Day 01 – Kathmandu Arrival
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Meet at Kathmandu Airport and Transfer to Hotel. Check in at hotel. Over Night Stay in Kathmandu</p>
                                                    <p>(Optional – Nepali Cultural Show with Dinner)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Day 02 – Kathmandu Sightseeing
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p> After Breakfast At 10 Visit - Pashupatinath / Boudhanath / Swyambhunath / Kathmandu Durbar.
                                                        Over Night Stay in Kathmandu</p>
                                                    <p>(Optional – Mt Everest Flight – 6:30 AM)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Day 03 – Kathmandu – Pokhara ( 200 KM – 5 Hours )
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast 10 AM Proceed For Pokhara. Enjoy Scenic Drive Along With River Trishuli.
                                                        Arrive Pokhara And Check, Overnight Stay In Pokhara</p>
                                                    <p>(Optional – Manokamana Cable Car / River Rafting)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Day 04 – Pokhara Sightseeing
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour " data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Early Morning 05:00 AM Visit – Sarangkot Sunrise Tour (2 Hours)
                                                        After Breakfast 10 AM Visit - Davis Fall / Gupteswar Cave / Vindhavasini / Seti Gorge (3 Hours)
                                                        After Lunch Day Free at Leisure OR Explore Boat Ride / Lake Side Market / Restaurant on Own.
                                                        Over Night Stay in Pokhara</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFive">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Day 05 – Pokhara – Chitwan ( 160 KM – 4 Hours )
                                                </button>
                                            </h2>
                                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast 10 AM, Proceed for Chitwan. Arrive At Chitwan &amp; Check-In at The Resort. Briefing Regarding Jungle Activities &amp; Resort Facilities.</p>
                                                    <p> Tharu Cultural Show. Dinner At the Resort. Overnight Stay in Chitwan.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingSix">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    Day 06 – Chitwan - Jungle Activities
                                                </button>
                                            </h2>
                                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast Enjoy Jungle Activities - Elephant-Back Safari / Canoe Ride / Nature Walk / Bird Watching Etc.
                                                        Overnight Stay in Chitwan</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingSeven">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    Day 07 – Chitwan – Kathmandu (160 Kms. – 5 Hours)
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast, Proceed to Kathmandu. Arrive At Kathmandu and Check-In at Hotel. Overnight Stay in Kathmandu</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingEight">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    Day 08 : Kathmandu – Departure
                                                </button>
                                            </h2>
                                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast Transfer To Kathmandu International Airport </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {   /* ////////////////////////////////////////////////////// section 4 ///////////////////////////////////////////////////////// */}

            <section className='ToursInfo-Section4'>
                <div className='container'>
                    <div className='Offer'>
                        <div className='startAt'>
                            <div className='Start'>
                                <p>Package Start @</p>
                                <h5>Rs 30,000/- + 5% GST</h5>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='ToursInfo-Section4-Data'>
                                <div className='Cost-Includes'>
                                    <h4>Includes :</h4>
                                    <hr></hr>
                                    <ul className='list_ok'>
                                        <li>03 Nights Stay in Kathmandu with Breakfast</li>
                                        <li>02 Nights Stay in Pokhara with Breakfast</li>
                                        <li>02 Night Stay in Chitwan with Breakfast + Lunch + Dinner</li>
                                        <li>Two International Airport Transfer in Kathmandu by Pvt Air Condition Vehicle</li>
                                        <li>Kathmandu Sightseeing by Pvt Air Condition Vehicle</li>
                                        <li>Kathmandu to Pokhara Transfer by Pvt Air Condition Vehicle</li>
                                        <li>Pokhara Sightseeing by Pvt Air Condition Vehicle</li>
                                        <li>Pokhara to Chitwan Transfer by Pvt Air Condition Vehicle</li>
                                        <li>All Jungle Activities Including Elephant Back Safari &amp; Jungle Entrance</li>
                                        <li>Chitwan to Nagarkot Transfer by Pvt Air Condition Vehicle</li>
                                        <li>All Applicable Hotel Tax 24.3% Included</li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Kathmandu Sightseeing :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Pasupatinath Temple / Bouddhanath Stupa / Swyambhunath Stupa / Kathmandu Durbar
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Pokhara Sightseeing :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Sarangkot Sunrise Tour / Davis Fall / Gupteshwar Mahadev Cave / Vindhavasini Temple / Seti Gorge
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Chitwan Activities :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Elephant Back Safari / Tahru Cultural Show / Canoeing / Jungle walk &amp; Bird Watching
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Exclude –</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Entrance Fees During Sightseeing / Tour Guide / Air Ticket
                                        </li>
                                    </ul>
                                </div>

                                <div className='Note'>
                                    <h4>Note :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Booking is subject to availability at the Time of Booking.
                                        </li>
                                        <li>
                                            Prices/Taxes are subject to changes and availability.
                                        </li>
                                        <li>
                                            Confirmed bookings will attract a cancellation fee.
                                        </li>
                                    </ul>
                                </div>

                                <div className='Important'>
                                    <h4>Important :-</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            All Passenger Must Carry Valid Indian Passport OR Election Card
                                        </li>
                                        <li>
                                            Vehicle Not At Disposal. All Transfer &amp; Sightseeing On Point To Point Basis
                                        </li>
                                        <li>
                                            Rates Valid For Indian Passport Holder Only.
                                        </li>
                                        <li>
                                            Any Additional Expenses Incurred Due to Flight Cancel/Delay/Natural Disaster/Political Action
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <ItineraryForm />
                            <div className='Side-Images'>

                                <div className='Side-Images01'>
                                    <img src={images01} alt="" />
                                </div>
                                <div className='Side-Images02'>
                                    <img src={images02} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent />


        </>
    )
}

export default KPCItinerary;