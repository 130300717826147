
import './App.css';
import NavBarCompnent from './Component/NavBarCompnent';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './Pages/HomePage';
import DestinationPage from './Pages/DestinationPage';

import HotelsPage from './Pages/HotelsPage';
import AboutUsPage from "./Pages/AboutUsPage";
import ContactUsPage from "./Pages/ContactUs";
import AdventuresPage from './Pages/AdventuresPage';
import KathmanduItinerary from './Component/KathmanduItinerary';
import KPCItinerary from './Component/KPCItinerary';
import KPItinerary from './Component/KPItinerary';
import KPMItinerary from './Component/KPMItinerary';
import KPNItinerary from './Component/KPNItinerary';
import KPCNItinerary from './Component/KPCNItinerary';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBarCompnent />

        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/nepal-tour-package' element={<DestinationPage />} />
          <Route path='/nepal-tour-package-for-couple' element={<AdventuresPage />} />
          <Route path='/best-nepal-tour-packages' element={<HotelsPage />} />
          <Route path='/nepal-tour-package-for-family' element={<AboutUsPage />} />
          <Route path='/nepal-travel-packages' element={<ContactUsPage />} />

          {/* /////////////////////////////////////////////////////////////////////////// */}

          <Route path='/nepal-tours-and-travels' element={<KathmanduItinerary />} />
          <Route path='/affordable-nepal-tour-packages' element={<KPItinerary/>} />
          <Route path='/cheap-nepal-tour-packages' element={<KPCItinerary />} />
          <Route path='/nepal-tourism-package' element={<KPMItinerary />} />
          <Route path='/nepal-holiday-packages' element={<KPNItinerary />} />
          <Route path='/affordable-nepal-holiday-packages' element={<KPCNItinerary />} />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
